<template>
    <infinite-loading @infinite="loadMore" spinner="circles" v-if="paginationData.total > 0 && paginationData.total > paginationData.page * paginationData.size" >
<!--        <span slot="no-more"></span>-->
<!--        <span slot="no-results"></span>-->
    </infinite-loading>
</template>

<script>
export default {
    name: "LazyloadLoadingLayout",
    inject: {
        setPage: {default: () => {}},
    },
    props: {
        paginationData: {
            type: Object,
            default: {
                total: 0,
                page: 0,
                size: 0,
            }
        },
        listLength: {
            default: 0
        },
        finishGetList: {default: true},
    },
    mixins: [],
    data() {
        return {
            loadingMore: false,
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        loadMore($event) {
            if(this.loadingMore) {
                return;
            }
            this.loadingMore = true;
            let page= this.paginationData.page;
            page = page + 1;
            this.setPage('page', page);
            this.lazyLoadComplete($event);
        },
        lazyLoadComplete($event) {
            setTimeout(() => {
                if (!this.finishGetList) {
                    this.lazyLoadComplete($event);
                    return false;
                }

                $event.loaded();
                this.loadingMore = false;
            }, 500)
        }
    }
}
</script>

<style scoped>

</style>